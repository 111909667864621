var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flex items-center justify-center",
      staticStyle: { height: "100vh", width: "100vh" }
    },
    [
      _vm.ready
        ? _c("div", [
            _c("img", {
              staticClass: "qrcode cursor-pointer",
              attrs: {
                src: this.product.data.qrcode_ref
                  .replace(/.svg/, ".png")
                  .replace("/upload/", "/upload/f_auto/"),
                width: "100%"
              },
              on: {
                click: function($event) {
                  return _vm.openURL(_vm.productShortURI)
                }
              }
            })
          ])
        : _c(
            "div",
            [
              _c("q-spinner-puff", {
                staticClass: "loading-spinner loading-spinner-gold",
                staticStyle: { color: "#F4A724" },
                attrs: { size: "200px" }
              })
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }