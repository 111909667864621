<template>
  <div class="flex items-center justify-center" style="height: 100vh; width: 100vh">
    <div v-if="ready">
      <img @click="openURL(productShortURI)" class="qrcode cursor-pointer" :src="this.product.data.qrcode_ref.replace(/.svg/, '.png').replace('/upload/', '/upload/f_auto/')" width="100%">
    </div>
    <div v-else>
      <q-spinner-puff
        style="color: #F4A724"
        size="200px" class="loading-spinner loading-spinner-gold"/>
    </div>
  </div>
</template>

<script>
import { axiosLIO } from 'plugins/axios'
import { openURL } from 'quasar'

export default {
  name: 'LayoutChannelProductWinglet',
  data () {
    return {
      uri: null,
      ready: false
    }
  },
  meta () {
    return {
      title: ['Wings', this.paramName].join(' · '),
      description: { name: 'description', content: 'Live channel for ' + this.paramName }
    }
  },
  computed: {
    paramName () {
      return this.$route.params.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    productFriendlyName () {
      return this.product.data.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    productFullURI () {
      return [document.location.origin, '/channel/', this.product.data.uri].join('')
    },
    productShortURI () {
      return this.product.data.shortlink
    },
    product: {
      get () {
        return this.$store.getters['app/getProducts'].list[0]
      },
      set (product) {
        this.$store.commit('app/updateProductPayload', 0, product)
      }
    }
  },
  beforeMount () {
    // query product information
    axiosLIO.get('/channel/' + this.$route.params.uri).then((res) => {
      let product = res.data.data.product
      product.data = JSON.parse(product.payload)
      this.$store.commit('app/updateProducts', { list: [product], group: {} })
      setTimeout(() => {
        this.ready = true
      }, 1200)
    })
  },
  methods: {
    openURL
  }
}
</script>

<style lang="stylus">
.q-loading-bar.top.bg-primary
  display none

html, body
  background-color transparent !important

// .qrcode
//   max-width 280px
// @media only screen and (min-width: 320px)
//   .qrcode
//     max-width 340px

.qrcode
  max-width 80%
@media only screen and (min-width: 328px)
  .qrcode
    max-width 280px
@media only screen and (min-width: 320px)
  .qrcode
    max-width 90%
@media only screen and (min-width: 400px)
  .qrcode
    max-width 390px

</style>
